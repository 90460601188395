import React from 'react'
import PropTypes from 'prop-types'
import Headroom from 'react-headroom'

import CloseIcon from '../../../static/images/close.svg'
import LangContext from '../../context/LangContext'
import NavItem from './NavItem'
import LanguageSelector from './LanguageSelector'
import MenuIcon from '../../../static/images/menu.svg'
import './menu.scss'

const Menu = (props) => {
  const [open, toggleMenuOpen] = React.useState(false)
  const { langPrefix } = React.useContext(LangContext)

  const getLogo = () => {
    const { logo } = props
    return <a href={langPrefix || '/'}><img src={logo.url} alt={logo.alt} className='menu-logo' /></a>
  }

  const formatItemData = (data) => {
    const {
      label, link, items: innerItems,
    } = data.item.document.data
    const { id } = data.item
    let formattedInnerItems
    if (innerItems && innerItems.length > 0) {
      formattedInnerItems = innerItems.map(formatItemData)
    }
    return {
      id, label, link, innerItems: formattedInnerItems,
    }
  }

  const toggleMenu = () => {
    if (!open) {
      document.body.classList.add('noscroll')
    } else {
      document.body.classList.remove('noscroll')
    }
    toggleMenuOpen(!open)
  }

  const getMenuItems = (isBurgerMenu) => {
    const { items, langs, location } = props
    const menuItems = (items.map(formatItemData).map((item) => (
      <NavItem isBurgerMenu={isBurgerMenu} item={item} toggleMenu={toggleMenu} />
    )))
    menuItems.push(<LanguageSelector langs={langs} location={location} />)
    return menuItems
  }

  if (!open) {
    return (
      <div className='menu-display side-padding'>
        <Headroom style={{ transition: 'all .5s ease-in-out' }}>
          <div className='max-width-container'>
            <div className='page-container menu-container'>
              {getLogo()}
              <div className='menu-items-container'>
                {getMenuItems()}
              </div>
            </div>
            <div className='menu-burger'>
              {getLogo()}
              <button
                className='menu-burger-button'
                type='button'
                onClick={toggleMenu}
              >
                <MenuIcon />
              </button>
            </div>
          </div>
        </Headroom>
      </div>)
  }
  return (
    <div className='menu-overlay'>
      <div className='overlay-header'>
        {getLogo()}
        <button href type='button' className='menu-closebtn' onClick={toggleMenu}>
          <CloseIcon />
        </button>
      </div>
      <div className='menu-overlay-content'>
        {getMenuItems(true)}
      </div>
    </div>)
}

Menu.propTypes = {
  logo: PropTypes.object,
  items: PropTypes.array,
  langs: PropTypes.array,
  location: PropTypes.object.isRequired,
}

export default Menu
