import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import LanguageSelector from '../components/menu/LanguageSelector'
import './footer.scss'

const Footer = ({ data, langs, location }) => {
  const {
    logo, address, email, phone, links,
  } = data
  return (
    <div className='gray-background footer-container'>
      <div className='max-width-container side-padding'>
        <div className='footer-wrapper'>
          <div className='footer-left-column'>
            <div className='logo-container'>
              <img src={logo.url} alt='Marine flex logo' />
            </div>
            <div className='footer-contact-details'>
              <p>{address}</p>
              <p><a href={`mailto:${email}`}>{email}</a></p>
              <p><a href={`tel:${phone.replace(' ', '')}`}>{phone}</a></p>
            </div>
          </div>

          <div className='column-fill' />
          <div className='links-wrapper'>
            <div>
              <Link to='/'>Home</Link>
            </div>
            {links.map(({ linkItem }) => (
              <div key={linkItem.id}>
                <Link to={linkItem.document.data.link}>{linkItem.document.data.label}</Link>
              </div>
            ))}
            <div className='language-selector-footer'><LanguageSelector location={location} langs={langs} /></div>
          </div>
        </div>
        <div className='credits-wrapper'>
          <div><small>© Marine Flex. All rights reserved.</small></div>
          <div>
            <small>Website built by <a href='https://thehype.nz' target='_blank' rel='noopener noreferrer'>The Hype - Digital Agency</a></small>
            <small> v2.0</small>
          </div>
        </div>
      </div>
    </div>
  )
}

Footer.propTypes = {
  data: PropTypes.object.isRequired,
  langs: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
}

export default Footer
