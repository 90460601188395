import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import LangContext from '../../context/LangContext'
import Dropdown from './dropdown'

const NavItem = ({ item, isBurgerMenu, toggleMenu }) => {
  const { langPrefix } = React.useContext(LangContext)
  const renderMenuItem = (itemData) => (
    <a
      key={itemData.id}
      onClick={isBurgerMenu && toggleMenu}
      href={`${langPrefix}${itemData.link}`}
      className='menu-link'
    >
      {itemData.label}
    </a>
  )

  if (item.innerItems) {
    return (
      <Dropdown key={item.id} item={item}>
        {item.innerItems.map((innerItem) => (
          <li key={innerItem.id}>{renderMenuItem(innerItem)}</li>
        ))}
      </Dropdown>
    )
  }
  return renderMenuItem(item)
}

NavItem.propTypes = {
  isBurgerMenu: PropTypes.bool,
  toggleMenu: PropTypes.func,
  item: PropTypes.object,
}

export const query = graphql`
  fragment NavItemFragment on PrismicLinkType {
    id
    document {
      ... on PrismicNavItems {
        data {
          label
          link
          items {
            item {
              id
               document {
                ... on PrismicNavItems {
                  data {
                    label
                    link
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default NavItem
