import React from 'react'
import PropTypes from 'prop-types'

import { defaultLanguageName } from '../../prismic-config'
import LangContext from '../context/LangContext'
import OgTags from '../components/seo/tags'
import Menu from '../components/menu'
import Footer from '../layouts/footer'

const getLangName = (lang) => lang.split('-')[0]

const MasterPage = ({
  navBarData, footerData, children, ogData, location,
}) => {
  if (!navBarData) { return null } // DO NOT REMOVE THIS, BUILD WILL BREAK

  const pageLangName = getLangName(navBarData.lang)
  const [langContextValue] = React.useState({
    langPrefix: pageLangName !== defaultLanguageName ? `/${pageLangName}` : '',
  })
  const langs = [navBarData.lang].concat(navBarData.alternateLangs.map((l) => l.lang))

  return (
    <LangContext.Provider value={langContextValue}>
      <OgTags {...ogData} />
      <Menu
        langs={langs}
        items={navBarData.data.items}
        logo={navBarData.data.logo}
        location={location}
      />
      <div className='main-content'>{children}</div>
      <Footer data={footerData} langs={langs} location={location} />
      <link
        href='https://fonts.googleapis.com/css2?family=Cairo&family=Roboto:wght@100;300;500&display=swap'
        rel='stylesheet'
      />
    </LangContext.Provider>
  )
}

MasterPage.defaultProps = {
  ogData: {},
}

MasterPage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  navBarData: PropTypes.object, // Prismic data
  footerData: PropTypes.object, // Prismic data
  ogData: PropTypes.object, // Prismic data
  location: PropTypes.object.isRequired,
}

export default MasterPage
