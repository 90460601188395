import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

const commonTags = (ogTitle, ogDescription, ogKeywords, ogImage) => ([
  { name: 'title', content: ogTitle },
  { property: 'og:title', content: ogTitle },
  { name: 'description', content: ogDescription },
  { property: 'og:type', content: 'website' },
  { property: 'og:url', content: 'https://marineflex.com' },
  { property: 'og:description', content: ogDescription },
  { property: 'og:image', content: ogImage ? ogImage.url.replace('https://', 'http://') : '' },
  { property: 'og:image:url', content: ogImage ? ogImage.url.replace('https://', 'http://') : '' },
  { property: 'og:image:secure_url', content: ogImage ? ogImage.url : '' },
  { property: 'og:image:width', content: ogImage ? ogImage.dimensions.width : 0 },
  { property: 'og:image:height', content: ogImage ? ogImage.dimensions.height : 0 },
  // { property: 'og:image:type', content: ogImage ? ogImage.file.contentType : '' },
  { property: 'og:image:alt', content: ogImage ? ogImage.alt : '' },
  { name: 'keywords', content: ogKeywords },
  { name: 'msapplication-TileColor', content: '#00519F' },
  { name: 'theme-color', content: '#00519F' },
])

export const SeoTags = ({
  ogTitle, ogDescription, ogKeywords, favIco, ogImage, ogLogo,
}) => (
  <Helmet
    title={ogTitle}
    meta={commonTags(ogTitle, ogDescription, ogKeywords, ogImage, ogLogo)}
    script={[
      {
        name: 'company',
        type: 'application/ld+json',
        innerHTML: `
           {
             "@context": "http://schema.org",
             "@type": "LocalBusiness",
             "name": "Marine Flex",
             "telephone": "+64 221 118 781",
             "email": "info@marineflex.com",
             "description": "${ogDescription}",
             "url": "https://marineflex.com",
             "logo": "${ogLogo ? ogLogo.url : ''}",
             "image": "${ogLogo ? ogLogo.url : ''}",
             "address": {
               "@type": "PostalAddress",
               "addressCountry": "New Zealand",
               "addressLocality": "Picton",
               "addressRegion": "Picton",
               "streetAddress": "14 Kent Street"
             }
           }
         `,
      },
    ]}
  >
    <link rel='icon' href={favIco.url}  />
  </Helmet>
)

SeoTags.propTypes = {
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  ogKeywords: PropTypes.string,
  ogImage: PropTypes.object,
  ogLogo: PropTypes.object,
  favIco: PropTypes.object,
}

export default SeoTags
